import React from 'react';
import SEO from './SEO';

const TitleComponent = ({ title, description }: { title?: string, description?: string }) => {
  const defaultTitle = 'Dog Food Delivery NZ Made Grain Free';
  return (
    <SEO
      title={title || defaultTitle}
      description={description || ''}
      keywords={[ 
        `pet food new zealand`, 
        `pet food nz`, 
        `pet food`, 
        `dog food`, 
        `furbaby`, 
        `dog biscuits nz`, 
        `dog food online nz`, 
        `grain free dog food nz`, 
        `nz made dog food`, 
        `puppy food nz`
      ]}
      lang="EN"
    />
  );
};

export { TitleComponent };
