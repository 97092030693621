import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import { Link } from 'gatsby';
import Loader from 'components/common/Loader';
import { CartIcon } from 'components/CartIcon';
// import { LineItem } from './LineItem';
import styles from './checkoutDrawer.module.scss';
import close from 'assets/close.svg';
import { isLoggedIn } from 'services/auth.api';
import { BasketLine, BasketProductState, OrderType } from 'models';
import {
  basketProductLineMapSelector,
  productIdsInBasketSelector,
  removeLine,
  totalItemsCountSelector,
  updateLine,
  useAppDispatch,
  useAppSelector,
} from 'state';
import { UpdateLinePayload } from 'services/basket.api';
import { trackAddOrRemoveItem } from '../../utils';
import LineItem from '../LineItem/LineItem';

type Props = {
  open: boolean;
  closeDrawer: () => void;
};

const basketProductsInitialState: BasketProductState[] = [];

export const CheckoutDrawer = (props: Props) => {
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState(basketProductsInitialState);
  const [loading, setLoading] = useState(false);
  const totalItemsCount = useAppSelector(totalItemsCountSelector);
  const productIds = useAppSelector(productIdsInBasketSelector);
  const lines = useAppSelector(state => state.basket.lines);
  const productLineMap = useAppSelector(basketProductLineMapSelector);
  const { basket, isLoading: basketLoading } = useAppSelector(
    state => state.basket,
  );

  const allProducts = useAppSelector(state => state.products.entities);

  const fetchData = () => {
    setLoading(true);
    const productsInBasket = allProducts.filter(
      product => productIds.indexOf(product.id.toString()) !== -1,
    );

    const productsState = productsInBasket.map(product => {
      const lineIds = productLineMap[product.id];
      const line =
        lines.find(line => lineIds?.includes(line.id)) || ({} as BasketLine);
      return {
        ...product,
        quantity: line.quantity,
        frequency: basket?.frequency,
        lineId: line.id,
        basketId: line.basket_id,
        linePrice: line.price_incl_tax,
      };
    });
    setProducts(productsState);
    setLoading(false);
  };

  useEffect(() => {
    document.body.style.overflow = props.open ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [props.open]);

  useEffect(() => {
    if (!props.open) return;
    fetchData();
  }, [props.open, lines, basket, allProducts]);

  const removeBasketItem = async (product: BasketProductState) => {
    trackAddOrRemoveItem(product, 'remove', product.quantity);
    await dispatch(removeLine(product.lineId));
  };

  const updateLineItem = async (
    lineChanges: { [key: string]: any },
    product: BasketProductState,
  ) => {
    const diff = (lineChanges.quantity || 0) - (product.quantity || 0);
    if (diff > 0) {
      trackAddOrRemoveItem(product, 'add', diff);
    } else {
      trackAddOrRemoveItem(product, 'remove', -diff);
    }
    await dispatch(
      updateLine({
        ...(lineChanges as UpdateLinePayload),
        lineId: product.lineId,
      }),
    );
  };

  const drawerClass = classNames(styles.drawer, 'col-md-6', {
    [styles.close]: !props.open,
  });

  const canShowNotification =
    products.find(product => {
      return product.title.toLowerCase().includes('chicken dog box');
    }) != undefined;

  const handleCheckout = () => {
    isLoggedIn()
      ? navigate(`/checkout?ot=${OrderType.OneOff}`)
      : navigate(`/login?type=${OrderType.OneOff}`);
  };

  const baseShippingCharge = basket?.base_shipping_charge;

  if (loading && !products.length) {
    return (
      <div className="row">
        <div className={drawerClass}>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className={drawerClass}>
        {(loading || basketLoading || !(allProducts || []).length) && (
          <Loader />
        )}
        <div className={styles.header}>
          <div className={styles.closeBtnContainer} onClick={props.closeDrawer}>
            <img src={close} />
          </div>
          <h5 className={styles.title}>Order Summary</h5>
          <Button
            className="btn btn-alt"
            onClick={() => handleCheckout()}
            disabled={products.length === 0}
          >
            Checkout
          </Button>
          <div className={styles.cartPriceContainer}>
            <CartIcon itemCount={totalItemsCount} />
            <span className="price">${basket?.total_incl_tax}</span>
          </div>
        </div>
        {products.map((product, index) => {
          const isLastProduct = index === products?.length - 1;
          return (
            <React.Fragment key={product.id}>
              <LineItem
                productInfo={product}
                removeLineItem={() => removeBasketItem(product)}
                updateLineItem={lineChanges =>
                  updateLineItem(lineChanges, product)
                }
              />
              {!isLastProduct && <hr className={styles.seperator} />}
            </React.Fragment>
          );
        })}
        {canShowNotification && (
          <div className={styles.notification}>
            <div className={styles.title}>Save Time & Money!</div>
            <p>
              Subscribe to a personalised feeding plan so you never run out of
              food. You’ll also save money on every order, access better pricing
              for other products and get cheaper shipping.
            </p>
            <Link to="/getstarted" className="primary">
              {' '}
              Get Started
            </Link>
          </div>
        )}

        <div className={styles.footer}>
          <hr className={styles.seperator} />
          <div className={styles.subTotal}>
            <span>Cart Subtotal</span>
            <span>${basket?.total_incl_tax}</span>
          </div>

          {baseShippingCharge !== 0 && (
            <div className={styles.note}>
              <span>+ ${baseShippingCharge}</span>
            </div>
          )}

          <Button
            className="btn btn-alt"
            onClick={() => handleCheckout()}
            disabled={products.length === 0}
          >
            Continue to Checkout
          </Button>
          <Link to="/products">Continue Shopping</Link>
        </div>
      </div>
    </div>
  );
};

export default CheckoutDrawer;
