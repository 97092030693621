import React, { useState, useEffect } from 'react';

function ErrorBoundary({ children }: { children: any }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error: any) => {
      console.error("Error-boundary",{ error });
      setHasError(true);
      Sentry.captureException(error);
      Sentry.captureMessage('ErrorBoundary');
    };

    // Attach a global error handler
    window.addEventListener('error', handleError);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  if (hasError) {
    // You can render an error message or UI here
    return <div></div>;
  }

  return children;
}

export default ErrorBoundary;
