import React from 'react';
import { Link } from 'gatsby';
import logo from '../../assets/logo.png';
import logoDark from '../../assets/logo_dark.png';

export enum LogoType {
  WHITE = 'white',
  DARK = 'dark',
}

interface Props {
  href?: string;
  type?: LogoType;
  width?: string;
  height?: string;
}

export const Logo: React.FC<Props> = props => {
  const { href, type, width, height } = props;
  return (
    <div className="logo">
      <Link to={href || '/'}>
        <img
          alt="logo"
          title="logo"
          src={type && type === LogoType.DARK ? logoDark : logo}
          width={width ?? '130px'}
          height={height ?? '50px'}
        />
      </Link>
    </div>
  );
};
