import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { isLoggedIn } from 'services/auth.api';
import { Logo, LogoType } from './common/Logo';
import icn_insta from '../assets/icn_insta.png';
import icn_fb from '../assets/icn_fb.png';
import icn_plasticneutral from '../assets/footer/plastic_neutral_white.png';
import icn_sbn from '../assets/footer/sbn_logo_white.png';

//TODO: Images
export const Footer = () => {
  return (
    <footer>
      <div className="wrapper-top">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="row">
              <div className="col-6 col-md-3">
                <div className="ft-col-2">
                  <div className="ft-col-item-hdr cur-def">About</div>
                  <Link to="/howitworks/">
                    <div className="ft-col-item">How It Works</div>
                  </Link>
                  <Link to="/products/">
                    <div className="ft-col-item">Products</div>
                  </Link>
                  <Link to="/ingredients/">
                    <div className="ft-col-item">Ingredients</div>
                  </Link>
                  <Link to="/ourstory/">
                    <div className="ft-col-item">Our Story</div>
                  </Link>
                  <Link to="/aboutmyfurbaby/">
                    <div className="ft-col-item">About MyFurbaby</div>
                  </Link>
                  {isLoggedIn() ? (
                    <Link to="/account/dashboard/">
                      <div className="ft-col-item">Dashboard</div>
                    </Link>
                  ) : (
                    <Link to="/getstarted/">
                      <div className="ft-col-item">Get Started</div>
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="ft-col-2">
                  <div className="ft-col-item-hdr cur-def">Help</div>
                  <Link to="/blog/">
                    <div className="ft-col-item">Blog</div>
                  </Link>
                  <Link to="/faq/">
                    <div className="ft-col-item">FAQ</div>
                  </Link>
                  {/* <Link to="/#"><div className="ft-col-item">Payment & Returns</div></Link> */}
                  <Link to="/termsandconditons/">
                    <div className="ft-col-item">Terms and Conditions</div>
                  </Link>
                  <Link to="/deliveryandreturns/">
                    <div className="ft-col-item">Delivery and Returns</div>
                  </Link>
                  <Link to="/support/">
                    <div className="ft-col-item">Support</div>
                  </Link>
                  <Link to="/shippingcosts/">
                    <div className="ft-col-item">Shipping Costs</div>
                  </Link>
                  {/* TODO: Post launch */}
                  {/* <Link to="#"><div className="ft-col-item">Terms & Conditions</div></Link> */}
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="ft-col-2">
                  <div className="ft-col-item-hdr cur-def">
                    Customer Service
                  </div>
                  <div className="ft-col-item cur-def">Support Center</div>
                  <a href="tel:+64 9886 9738">
                    <div className="ft-col-item">+64 9 886 9738</div>
                  </a>
                  <a href="mailto:info@feedmyfurbaby.co.nz">
                    <div className="ft-col-item text-underline">
                      info@feedmyfurbaby.co.nz
                    </div>
                  </a>
                  {/* <a href="mailto:press@feedmyfurbaby.co.nz"><div className="ft-col-item text-underline">press@feedmyfurbaby.co.nz</div></a> */}
                </div>
              </div>
              <div className="col-6 col-md-3 ft-bot-md-or">
                <div className="logo-wrapper text-center">
                  <Logo type={LogoType.DARK} width={'159px'} height={'61px'} />
                  <div className="fl-ft-or">
                    <span>Follow Us</span>
                    <a
                      href="https://www.facebook.com/myfurbabynz"
                      target="_blank"
                    >
                      <img className="ft-img-fb" src={icn_fb} alt="fb-icon" />
                    </a>
                    <a
                      href="https://www.instagram.com/myfurbabynz/"
                      target="_blank"
                    >
                      <img
                        src={icn_insta}
                        alt="twitter-icon"
                        title="twitter-icon"
                      />
                    </a>
                  </div>
                  <div className="fl-ft-logo">
                    <img className="ft-img-fb" src={icn_sbn} alt="fb-icon"  width={'40%'} height={'50%'}/>
                    <img className="ft-img-fb" src={icn_plasticneutral} alt="twitter-icon" width={'40%'} height={'50%'}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-bot">
        <div className="container-fluid">
          <div className="ft-bot-wrap">
            <div className="ft-bot-item">
              &copy; Copyright FeedMyFurbaby {new Date().getFullYear()}. All
              Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
