import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import close from '../../assets/close.png';
import './styles.scss';

type Props = {
  children: React.ReactNode;
  action: () => void;
  body?: string;
  title?: string;
  submitLabel?: string;
};

const PopConfirm = ({
  children,
  action,
  body,
  title = 'Are you Sure?',
  submitLabel = 'Submit',
}: Props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <span className="cur-pointer" onClick={() => setShow(true)}>
        {children}
      </span>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        dialogClassName="popConfirmationModal"
        size="lg"
      >
        <Modal.Header>
          <img
            className="cur-pointer close-icon"
            onClick={() => setShow(false)}
            src={close}
            alt="Close"
          />
        </Modal.Header>
        <Modal.Body className="text-center">
          <h4 className="modal-title">{title}</h4>
          {body && <p>{body}</p>}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-wrapper">
            <Button
              className="cur-pointer btn-small bgColorGray"
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="cur-pointer btn-small"
              variant="primary"
              onClick={() => {
                setShow(false);
                action();
              }}
            >
              {submitLabel}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PopConfirm;
