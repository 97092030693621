import React from 'react';
import styles from './cartIcon.module.scss';
import cartIcon from 'assets/shopping_cart.png';

interface Props {
  itemCount: number;
  onClick?: () => void;
}
export const CartIcon = (props: Props) => {
  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div className={styles.cartIconContainer} onClick={() => handleOnClick()}>
      <div className={styles.itemCount}>{props.itemCount}</div>
      <img src={cartIcon} />
    </div>
  );
};

export default CartIcon;
