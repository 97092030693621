import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import { CartIcon } from 'components/CartIcon';
import Overlay from 'components/Overlay';
import icn_menu from '../assets/icn_menu.png';
import { Logo } from './common/Logo';
import { isLoggedIn, logout } from '../services/auth.api';
import { getVouchers } from '../services/voucher.api';
import {
  removeBasket,
  useAppDispatch,
  useAppSelector,
  totalItemsCountSelector,
  createNewBasket,
} from 'state';
import PopConfirm from './PopConfirm';
import CheckoutDrawer from './CheckoutDrawer/CheckoutDrawer';

const navItems = [
  {
    text: 'Dashboard',
    link: '/account/dashboard/',
  },
  {
    text: 'My Orders',
    link: '/account/orders/',
  },
  {
    text: 'My Account',
    link: '/account/info/',
  },
  {
    text: 'reFUR a friend',
    link: '/account/refer/',
  },
  {
    text: 'Blog',
    link: '/blog/',
  },
  {
    text: 'Shop',
    link: '/products/',
  },
];

export const AccountHeader = props => {
  const dispatch = useAppDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const totalItemsCount = useAppSelector(totalItemsCountSelector);
  const [vouchers, setVouchers] = useState([]);
  const [isVoucher, setIsVoucher] = useState(false);

  useEffect(() => {
    const fetchVouchers = async () => {
      try {
        const fetchedVouchers = await getVouchers();
        setVouchers(fetchedVouchers);
        setIsVoucher(fetchedVouchers && fetchedVouchers.length > 0);
      } catch (error) {
        
      }
    };
    fetchVouchers();
  }, []);

  const clearAndLogout = async () => {
    event.preventDefault();
    await dispatch(removeBasket());
    await dispatch(createNewBasket());//bug fix cart bug
    logout(() => navigate(`/?logout=true`));
  };

  return (
    <>
      <header
        className={classNames({ 'container-fluid': true, account: false })}
      >
        <div
          className={classNames({
            'menu-icn': true,
            open: props.mobileNavOpen,
          })}
          onClick={e => {
            e.preventDefault();
            props.setMobileVisibility(!props.mobileNavOpen);
          }}
        >
          <img src={icn_menu} />
        </div>
        <Logo />
        <nav
          className={classNames({ nav: true, 'nav-open': props.mobileNavOpen })}
        >
          <ul>
            {navItems.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.link}
                  activeClassName="active"
                  onClick={event => {
                    props.setMobileVisibility(false);
                  }}
                  className="menu-item"
                >
                  {item.text}{isVoucher && item.text == 'My Account' ? (<span className="red-circle">
                    <span className="dollar-sign">$</span>
                  </span>) : null}
                </Link>
              </li>
            ))}
            <li className="log-wrapper">
              {isLoggedIn() ? (
                <PopConfirm title='Are you sure you want to log out?' submitLabel="Logout" action={clearAndLogout}>
                  <a role='button'>Logout</a>
                </PopConfirm>
              ) : (
                <Link to="/login">Login</Link>
              )}
            </li>
          </ul>
        </nav>
        <div className="hdr-rt">
          <ul>
            <li className="log-wrapper">
              {isLoggedIn() ? (
                <PopConfirm title='Are you sure you want to log out?' submitLabel="Logout" action={clearAndLogout}>
                  <a role='button'>Logout</a>
                </PopConfirm>
              ) : (
                <Link to="/login">Login</Link>
              )}
            </li>
          </ul>
        </div>
        <div className="header-cart-icon">
          <CartIcon
            itemCount={totalItemsCount}
            onClick={() => setDrawerOpen(true)}
          />
        </div>
      </header>
      <Overlay show={drawerOpen} onClick={() => setDrawerOpen(false)} />
      <CheckoutDrawer
        open={drawerOpen}
        closeDrawer={() => setDrawerOpen(false)}
      />
    </>
  );
};
