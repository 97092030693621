import { useFormik } from 'formik';
import { Form, Col } from 'react-bootstrap';
import React from 'react';
import styles from './lineItem.module.scss';
import { BasketProductState } from 'models';
import { getProductImageUrl, getImageSource } from '../../utils';
import product_placeholder from '../../assets/product_placeholder.png';

interface Props {
  productInfo: BasketProductState;
  removeLineItem: () => {};
  updateLineItem: (values: {
    [key: string]: string | number | undefined;
  }) => {};
}

export const LineItem = (props: Props) => {
  const productInfo = props.productInfo;
  const initialValues = {
    quantity: productInfo?.quantity,
  };

  const maxQuantity = productInfo?.items_in_cart_limit || 6;
  const quantityArray = [...Array(maxQuantity).keys()];

  const formik = useFormik({
    initialValues,
    onSubmit: values => {},
    validate: values => {
      const quantity = values?.quantity || 0;
      if (quantity > maxQuantity) {
        return { quantity: `Cannot more than ${maxQuantity}` };
      }
      props.updateLineItem(values);
      return {};
    },
    validateOnChange: true,
    enableReinitialize: true,
  });

  const imageSrc = getImageSource(productInfo);

  return (
    <div className={styles.lineContainer}>
      <img
        src={imageSrc ? getProductImageUrl(imageSrc) : product_placeholder}
        alt={productInfo?.title}
      />
      <div className={styles.itemWrapper}>
        <div className={styles.itemHeader}>
          <div className={styles.title}>{productInfo?.title}</div>
          <a onClick={() => props.removeLineItem()}>Remove</a>
        </div>
        <div className={styles.itemBody}>
          <Form noValidate={true}>
            <Form.Row>
              <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="col-5 mr-3"
              >
                <Form.Label className={styles.label}>Quantity</Form.Label>
                <Form.Control
                  as="select"
                  onChange={formik.handleChange}
                  value={formik.values.quantity}
                  name="quantity"
                  className="cust-sel"
                  isInvalid={!!formik.errors.quantity}
                >
                  {quantityArray.map(index => (
                    <option key={index} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </Form.Control>
                {formik.errors.quantity ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.quantity}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail" className="col-5">
                <Form.Label className={styles.label}>Frequency</Form.Label>
                <div className={styles.lightText}>
                  {productInfo?.frequency ? 'Repeat' : 'One off'}
                </div>
              </Form.Group>
            </Form.Row>
          </Form>
          <div className={styles.price}>${productInfo?.linePrice}</div>
        </div>
      </div>
    </div>
  );
};

export default LineItem;
