import React from 'react';
import styles from './overlay.module.scss';

interface Props {
  onClick?: () => void;
  zIndex?: number;
  show: boolean;
}
export default (props: Props) => {
  const handleOnClick = () => {
    props.onClick && props.onClick();
  };

  if (!props.show) return <></>;
  return (
    <div
      style={{ zIndex: props.zIndex || 100 }}
      className={styles.overlay}
      onClick={() => handleOnClick()}
    />
  );
};
